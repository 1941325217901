<template>
  <v-container v-if="!loading">
    <v-card>
      <v-card-title>Edit a Product</v-card-title>
      <v-divider class="ma-0"></v-divider>
      <v-card-text>
        <v-form v-model="valid">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step editable :complete="e1 > 1" step="1">
                Edit a Product
                <small>Basic Details of a Product</small>
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step
                editable
                v-if="showVariation"
                :complete="e1 > 2"
                step="2"
              >
                Add a Variations of Product
                <small
                  >Add all the Variations of Product ( Size, Color, Price and
                  Image )</small
                >
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <basic-details
                  @category="category"
                  @subcategory="subcategory"
                  @updateStep="updateStep"
                  @haswarranty="hasWarranty"
                  @nowarranty="noWarranty"
                  @novariation="noVariation"
                  @hasvariation="hasVariation"
                  @pid="pid"
                  :colors="colors"
                  :sizes="sizes"
                  :product="product"
                  :editvariations="product.variations"
                  @variations="variations"
                  @submitAll="submit"
                ></basic-details>
              </v-stepper-content>

              <v-stepper-content step="2">
                <product-variation
                  @updateStep="updateStep"
                  @backStep="backStep"
                  :variations="product.variations"
                  :cakes="selectedcategory === 'cakes'"
                  :colors="colors"
                  :pid="product.id"
                  :sizes="sizes"
                  :variationson="variationsOn"
                  @multiVariationAdded="multiVariationAdded"
                ></product-variation>
                <v-btn
                  block
                  color="primary"
                  :loading="btnLoading"
                  :disabled="!submitFormFinally"
                  @click="submit"
                  >Submit</v-btn
                >
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
  <circular-loaders v-else></circular-loaders>
</template>
<script>
import { mapState, mapActions } from "vuex";
import ProductVariation from "../../components/addproduct/productvariation/ProductVariation.vue";
import CircularLoaders from "../../components/loaders/CircularLoaders.vue";
import BasicDetails from "../../components/addproduct/BasicDetails.vue";

export default {
  components: {
    BasicDetails,
    ProductVariation,
    CircularLoaders,
  },
  data() {
    return {
      valid: false,
      search: "",
      e1: 1,
      selectedcategory: "",
      selectedsubcategory: "",
      showWarranty: false,
      showVariation: false,
      variationsOn: [],
      loading: false,
      btnLoading: false,
      id: "",
      submitFormFinally: false,
      product: {},
    };
  },
  computed: {
    ...mapState({
      colors: (state) => state.colors,
      sizes: (state) => state.sizes,
      products: (state) => state.products,
    }),
    restrictFields() {
      // electronic-devices-accessories
      if (this.selectedcategory === "electronic-devices-accessories") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["getUncompleteData"]),
    multiVariationAdded(val) {
      this.submitFormFinally = val;
    },
    category(cat) {
      this.selectedcategory = cat.slug;
    },
    subcategory(subcat) {
      this.selectedsubcategory = subcat.slug;
    },
    updateStep() {
      this.e1 = parseInt(this.e1) + 1;
    },
    backStep() {
      this.e1 = parseInt(this.e1) - 1;
    },
    hasWarranty() {
      this.showWarranty = true;
    },
    noWarranty() {
      this.showWarranty = false;
    },
    hasVariation() {
      this.showVariation = true;
    },
    noVariation() {
      this.showVariation = false;
    },
    pid(val) {
      this.id = val;
    },
    submit() {
      this.btnLoading = true;
      this.$store.dispatch("productadd", this.product.id).then(() => {
        // this.$store.commit("addProduct", res.data);
        this.$router.push("/products");
      });
    },
    variations(data) {
      data.color === true ? this.variationsOn.push("color") : "";
      data.type === true ? this.variationsOn.push("type") : "";
      data.size === true ? this.variationsOn.push("size") : "";
      data.weight === true ? this.variationsOn.push("weight") : "";
    },
  },
  beforeMount() {
    this.product = this.products.filter(
      (item) => item.id === this.$route.params.id
    )[0];
    if (this.product) {
      this.hasVariation();
      const variationType = this.product.variations[0];
      const arr = Object.keys(variationType).filter((item) => {
        return (
          item !== "id" &&
          item !== "stocks" &&
          item !== "price" &&
          item !== "selling_price" &&
          variationType[item] !== null
        );
      });
      if (arr.includes("colors")) {
        arr.splice(arr.indexOf("colors"), 1, "color");
      }
      this.variationsOn.push(...arr);
    }
  },
  mounted() {
    if (!this.$store.state.colors.length) {
      this.$store.dispatch("getColors");
    }
    if (!this.$store.state.sizes.length) {
      this.$store.dispatch("getSizes");
    }
  },
  // created() {
  //   this.loading = true;
  //   this.getUncompleteData().then(() => {
  //     this.loading = false;
  //   });
  // },
};
</script>
<style lang="scss">
.v-input__slot {
  border-radius: $border-radius !important;
}
.quillWrapper {
  .ql-snow.ql-toolbar,
  .ql-container {
    border-radius: $border-radius;
  }
}
</style>
